import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import {
  above,
  Zone,
  Button,
  DownloadButton,
  Box,
  BoxInner,
  BoxWrapper,
  LinkWrapper,
  richGrey,
} from '../elements'
import ita from '../images/affiliates/ita-logo.svg'
import idahoprivate from '../images/affiliates/private75-logo.svg'
import idahoprivate100 from '../images/affiliates/2018-idaho-private-100-logo.svg'
import smartway from '../images/affiliates/smartway-logo.svg'
import olympics from '../images/affiliates/special-olympics-logo.svg'
import wit from '../images/affiliates/wit-logo.svg'
import ymca from '../images/affiliates/ymca-logo.svg'
import mcleod from '../images/affiliates/mcleod-logo.svg'

const AffiliatesAwardsPage = ({ data }) => {
  const postNode = {
    title: `Awards & Affiliates - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Awards & Affiliates - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <Zone modifiers={['blank', 'short', 'center']} hero>
        <div className="zone-content">
          <h2>Awards & Affiliates</h2>
          <h3>
            D&D has a strong committment to our local community and industry.
          </h3>
          <p>
            Below are just a few of the affiliations and organizations that we
            are proud to be part of.
          </p>
          <Button to="/culture">
            Learn About Our Culture&nbsp;
            <i className="material-icons">chevron_right</i>
          </Button>
        </div>
      </Zone>

      <Container>
        {/* <PageTitle>Drivers</PageTitle> */}
        <BoxWrapper>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.idtrucking.org/"
              target="_blank"
            >
              <LogoImg>
                <img src={ita} alt="Idaho Trucking Association" />
              </LogoImg>
              <BoxInner>
                <h4>Idaho Trucking Association</h4>
                <p>
                  The Idaho Trucking Association has been serving Idaho since
                  1937 with a mission is to represent the interests of the
                  trucking industry with one united voice.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://www.idtrucking.org/"
                  target="_blank"
                >
                  Visit Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://issuu.com/idahostatesman/docs/1027_idaho_private_75_32p"
              target="_blank"
            >
              <LogoImg>
                <img src={idahoprivate} alt="Idaho Private 75" />
              </LogoImg>
              <BoxInner>
                <h4>Idaho Private 75</h4>
                <p>
                  The Idaho Private 75 is a tribute from a joint venture between
                  the Idaho Statesman Newspaper, US Bank of Idaho and KPMG. D&D
                  was featured in the 2004 and 2005 Idaho Statesman feature.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://issuu.com/idahostatesman/docs/1027_idaho_private_75_32p"
                  target="_blank"
                >
                  Visit Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.idahostatesman.com/news/business/article41572389.html"
              target="_blank"
            >
              <LogoImg>
                <img src={idahoprivate100} alt="Idaho Trucking Association" />
              </LogoImg>
              <BoxInner>
                <h4>Idaho Private 100</h4>
                <p>
                  The Idaho Private 100 is a tribute from a joint venture
                  between the Idaho Statesman Newspaper, US Bank of Idaho and
                  KPMG. D&D was featured in the 2017 and 2018 Idaho Statesman
                  feature.
                </p>
                <DownloadButton
                  style={{ marginRight: '1rem' }}
                  rel="noopener noreferrer"
                  href="https://issuu.com/idahostatesman/docs/ip100_2017_40p/22"
                  target="_blank"
                >
                  Visit 2017 Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://www.idahostatesman.com/news/business/article41572389.html"
                  target="_blank"
                >
                  Visit 2018 Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.epa.gov/smartway/smartway-partner-list"
              target="_blank"
            >
              <LogoImg>
                <img src={smartway} alt="Smartway Transportation Partner" />
              </LogoImg>
              <BoxInner>
                <h4>Smartway Transportation Partner</h4>
                <p>
                  D&D has also been a proud member of the industry-leading U.S.
                  EPA SmartWay® Transport Partnership since 2009 and one of the
                  Excellence Award Winners in 2015.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://www.epa.gov/smartway/smartway-partner-list"
                  target="_blank"
                >
                  Visit SmartWay&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>

              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.idso.org/idaho/"
              target="_blank"
            >
              <LogoImg>
                <img src={olympics} alt="Special Olympics of Idaho" />
              </LogoImg>
              <BoxInner>
                <h4>Idaho Special Olympics</h4>
                <p>
                  Through the years, D&D has provided sponsorship and support
                  for the Idaho Special Olympics–a non-profit that provides
                  training and athletic competitions, year-round, free of
                  charge, to all with intellectual disabilities throughout the
                  state.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://www.idso.org/idaho/"
                  target="_blank"
                >
                  Visit Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.womenintrucking.org/"
              target="_blank"
            >
              <LogoImg>
                <img src={wit} alt="Women in Trucking" />
              </LogoImg>
              <BoxInner>
                <h4>Women in Trucking</h4>
                <p>
                  D&D is a proud member of Women In Trucking Association–a
                  non-profit organization with the mission to encourage the
                  employment of women in the trucking industry, promote their
                  accomplishments, and minimize obstacles faced by women working
                  in the industry.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://wt.memberclicks.net/index.php?option=com_community&view=profile&userid=1001570580&module=view-all&uuid=e715aa0f-ef61-4622-b141-9ba0bbbaaaf5&current_page=5&directory_search_id=0"
                  target="_blank"
                >
                  Visit Member Profile&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.ymcatvidaho.org/"
              target="_blank"
            >
              <LogoImg>
                <img src={ymca} alt="YMCA of Idaho" />
              </LogoImg>
              <BoxInner>
                <h4>YMCA of Treasure Valley</h4>
                <p>
                  D&D proudly supports the Treasure Valley YMCA who strengthens
                  the foundations of community by developing successful youth,
                  engaging people in healthy living, and instilling a commitment
                  to social responsibility.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://www.ymcatvidaho.org/"
                  target="_blank"
                >
                  Visit Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
          <Box>
            <AffiliateBox
              rel="noopener noreferrer"
              href="https://www.mcleodsoftware.com/"
              target="_blank"
            >
              <LogoImg>
                <img src={mcleod} alt="McLeod Software" />
              </LogoImg>
              <BoxInner>
                <h4>McLeod Software</h4>
                <p>
                  D&D uses McLeod Software, a leader in software for trucking
                  dispatch operations management, freight brokerage management,
                  fleet management, document imaging, workflow, EDI, and
                  business process automation solutions.
                </p>
                <DownloadButton
                  rel="noopener noreferrer"
                  href="https://www.mcleodsoftware.com/"
                  target="_blank"
                >
                  Visit Link&nbsp;
                  <i className="material-icons">call_made</i>
                </DownloadButton>
              </BoxInner>
            </AffiliateBox>
          </Box>
        </BoxWrapper>
      </Container>
    </Layout>
  )
}

export default AffiliatesAwardsPage

export const Img = styled.img`
  display: none;
  ${above.md`
    display: flex;
    position: absolute;
    max-width: 340px;
    top: 15rem;
    right: 3rem;
  `}
  ${above.lg`
    position: absolute;
    top: 2rem;
    right: 6rem;
    max-width: 340px;
  `}
`
const LogoImg = styled.div`
  text-align: center;
  padding: 2rem;
  background: ${richGrey};
`
const AffiliateBox = styled.a`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  color: ${props => props.theme.colors.base};
  text-decoration: none;
  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 60%;
    ${above.md`
      padding-bottom: ${props => (props.featured ? '40%' : '60%')};
    `}
  }
`
